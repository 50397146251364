(function ($, window, document, undefined) {

	'use strict';

	$(function () {
		$('.js-scroll-next').on('click', function(e) {
			e.preventDefault();
			var cur = $(this).parents('.slide').index() + 1;
		    $('html, body').animate({
		        scrollTop: $('.slide:eq(' + cur + ')').offset().top
		    }, 1000);
		});

		$('.graph-mission__item').matchHeight();

		$('.js-accordion-toggle').on('click', function(e) {
			e.preventDefault();
			$(this).parents('.accordion__item').toggleClass('is-active');
			$(this).parents('.accordion__item').find('.accordion__content').slideToggle();
		});

		enquire.register("screen and (max-width:1023px)", {
			match : function() {
				$('.parent').on('click', function(e) {
					e.preventDefault();
					$(this).toggleClass('is-active');
					$(this).next('ul').toggleClass('is-active').slideToggle();
				});

				$('.mobile-menu').on('click', function(e) {
					e.preventDefault();
					$(this).toggleClass('is-active');
					$('.nav').toggleClass('is-active').slideToggle();
				});
			},  
			unmatch : function() {
				$('.parent').off('click');
				$('.nav').show();
			}
		});
	});


})(jQuery, window, document);
